<template>
  <a-modal
    v-model="editProfitVisible"
    title="利润管理"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="680px"
  >
    <div class="container">
      <a-form-model ref="ruleForm" class="ruleForm" :model="query" v-bind="layout" labelAlign="right">
        <a-form-model-item
          label="策略位置"
        >
          <a-input disabled :value="configType === '1' ? '供应商配置详情' : '广告位配置详情'"></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="广告源"
          v-if="from === 'flow'"
        >
          <a-input disabled :value="!isBatch ? sourceInfo.dspName : `批量操作${batchIdList.length}条`"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :label="configType === '1' ? '供应商' : '广告位'"
          v-if="from === 'budget'"
        >
          <a-input disabled :value="(!isBatch && configType === '1') ? sourceInfo.publisherName : (!isBatch && configType === '2') ? sourceInfo.placeName : `批量操作${batchIdList.length}条`"></a-input>
        </a-form-model-item>
        <a-form-model-item
          prop="strategySts"
          label="利润策略"
        >
          <a-radio-group v-model="query.strategySts">
            <a-radio value="A">
              {{ (+sourceType || +query.sourceType) ? '开启' : '自定义策略' }}
            </a-radio>
            <a-radio value="S">
              <template v-if="+sourceType || +query.sourceType">关闭</template>
              <template v-else>继承{{ configType === '1' ? '供应商' : '广告位' }}策略</template>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <PricingStrategyFormForAdx
          v-if="query.strategySts === 'A'"
          @clearValidate="changeSetForFormquery"
          :showUserDefine="configType === '2' "
          :query="query"
          @changeQuery="changeFormQuery"
        />
        <CustomStrategyForm v-if="query.strategySts === 'A' && +query.platId === 1046" :query="query" @clearValidate="changeSetForFormquery"></CustomStrategyForm>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import PricingStrategyFormForAdx from '@/components/sourceModal/PricingStrategyFormForAdx'
import CustomStrategyForm from '@/components/sourceModal/CustomStrategyForm'
import { mapState } from 'vuex'
import { updatePublisherDsp } from '@/apiForManage/flow/config'
export default {
  components: { PricingStrategyFormForAdx, CustomStrategyForm },
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      query: {}
    }
  },
  props: {
    sourceType: {
      default: 0,
      type: [Number, String]
    },
    visible: {
      type: Boolean,
      default: false
    },
    isBatch: {
      type: Boolean,
      default: true
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    configType: {
      // 1 供应商 2 广告位
      default: undefined,
      type: String,
      required: true
    },
    batchIdList: {
      default: () => ([]),
      type: Array
    },
    from: {
      default: '',
      type: String
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      skip: (state) => state.user.skip,
      role: (state) => state.user.roles[0],
      mediaAccess: (state) => state.user.mediaAccess
    }),
    editProfitVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          if (this.isBatch) {
            this.query = {
              creativeTypeList: [],
              strategySts: 'S',
              bidfloorStrategy: '0',
              bidfloorValue: 0,
              mediaSettleStrategy: '0',
              mediaSettleValue: 0,
              dspSettleStrategy: '0',
              dspSettleValue: 0,
              dealid: undefined,
              sts: 'A'
            }
          } else {
            this.query = {
              ...this.sourceInfo
            }
          }
        }
      }
    }
  },
  methods: {
    changeFormQuery (e) {
      this.query = JSON.parse(JSON.stringify(e))
    },
    // 切换竞价底价策略 清空校验
    changeSetForFormquery (data) {
      this.$refs.ruleForm.clearValidate()
    },
    handleCancel () {
      this.editProfitVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const query = {
            ...this.query,
            type: this.configType
          }
          if (!this.isBatch) {
            query.ids = [this.sourceInfo.id]
          } else {
            query.ids = this.batchIdList
          }
          const resp = await updatePublisherDsp(query)
          if (resp.code === 200) {
            this.$emit('editSuccess')
            this.$message.success('配置成功')
            this.editProfitVisible = false
          }
        } else {
          this.$message.error('请填写完整信息')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container{
  height: 400px;
  overflow-y: auto;
  // padding-left: 36px;
  // margin-right: 36px;
  .ruleForm{
    margin: 0 36px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #dbdee5;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #eff0f7;
  }
}
</style>
